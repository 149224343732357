import * as tensorflow from '@tensorflow/tfjs';

export default class ModelLoader {
     constructor(url) {
        this.modelPromice = tensorflow.loadLayersModel(url)
        this.waitForModel();
    }

    // static async build(url) {
    //     const modelLoader = new ModelLoader();
    //     modelLoader.modelPromice = await tensorflow.loadLayersModel(url)
    //     await modelLoader.waitForModel();
    //     return modelLoader;
    // }


    async waitForModel() {
        this.model = await this.modelPromice;
    }

    async runInference(imageData) {
        // console.log('imageData', imageData);
        let pixels = await tensorflow.browser.fromPixelsAsync(imageData)
        pixels = pixels.reshape([1, 80, 80, 3])
        pixels = tensorflow.div(pixels, 255)
        let predictions = this.model.predict(pixels)
        
        return predictions.dataSync()
    }
}

