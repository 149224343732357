import React, { useEffect, useState } from 'react';
import ModelLoader from '../ModelLoader';
import Scores from './Scores';
import FileBrowser from '../FileBrowser';

let modelCache = new ModelLoader(process.env.REACT_APP_BACKEND_URL + 'model_metadata');

function App() {
    const [scores, setScores] = useState(null);
    const [imageFile, setImageFile] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [dots, setDots] = useState('...');

    const imageStyle = imageFile ? {} : { display: 'none' };
    const imageId = 'image';

    useEffect(() => {
        if (imageFile) {
            setTimeout(recognise, 0);
        }
    }, [imageFile]);

    const recognise = async () => {
        let result = await modelCache.runInference(document.getElementById(imageId));
        setScores(result);
        setIsLoading(false);
    };

    const readFile = (event) => {
        let reader = new FileReader();

        reader.onload = (e) => {
            setImageFile(e.target.result);
            setScores(null);
        };
        if (event.target.files[0]) {
            setTimeout(() => reader.readAsDataURL(event.target.files[0]), 0);
        }
    };


    useEffect(() => {
        const interval = setInterval(() => {
            if (modelCache.model) {
                setIsLoading(false);
                clearInterval(interval);
            }
            setDots(dots => dots.length === 3 ? '' : dots + '.');
        }, 500);
        return () => clearInterval(interval);
    }, []);

    const [showFileBrowser, setShowFileBrowser] = useState(false);

    function handleToggleFileBrowser() {
        setShowFileBrowser(!showFileBrowser);
    }

    if (isLoading) {
        return <h1 style={{
            width: '90px'
        }}>Loading{dots}</h1>;
    }

    return (
        <div className="container">
            <h1>Blood Cell Type Recognition</h1>
            <div className="control-area">
                <label className="button">
                    {imageFile ? 'Upload New Image' : 'Upload Image'}
                    <input onChange={readFile} type="file" className="file-input" />
                </label>
            </div>
            <div className="central">
                <div style={imageStyle}>
                    {imageFile && (
                        <>
                            <img
                                src={imageFile}
                                id={imageId}
                                height="80px"
                                width="80px"
                                style={{
                                    display: 'none',
                                }}
                            />
                            <img src={imageFile} className="base-image" alt="cell" />
                        </>
                    )}
                </div>
                {scores && <Scores scores={scores} />}
            </div>
            <h2
                onClick={handleToggleFileBrowser}
                className="link demo-files"
            >
                Demo files
            </h2>
            {showFileBrowser && <FileBrowser />}
        </div>
    );
}

export default App;
