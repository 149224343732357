import React, { useState } from 'react';
import FileListItem from './FileListItem';

function FolderListItem({ item: { name }, path }) {
    const [contents, setContents] = useState(null);

    function handleFolderClick() {
        // If contents are already loaded, just display them
        if (contents !== null) {
            setContents(null);
            return;
        }

        // Fetch contents of folder
        fetch(`${process.env.REACT_APP_BACKEND_URL}list?dir=${name}`)
            .then((res) => res.json())
            .then((data) => {
                setContents(data.items);
            })
            .catch((err) => console.error(err));
    }

    return (
        <li className='folder'>
            <div>
                <span
                    onClick={handleFolderClick}
                    className="link folder-name"
                >
                    {name}
                </span>
                {contents && (
                    <ul className='grid'>
                        {contents.map((item) =>
                            item.type === 'directory' ? (
                                <FolderListItem
                                    key={item.name}
                                    item={item}
                                    path={path + name + '/'}
                                />
                            ) : (
                                <FileListItem
                                    key={item.name}
                                    item={item}
                                    path={path + name + '/'}
                                />
                            )
                        )}
                        <li className='clr'></li>
                    </ul>
                )}
            </div>
        </li>
    );
}

export default FolderListItem;
