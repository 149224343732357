import React from 'react';
import Recognition from './Recognition';

function App() {


    return (
        <div>
            <div className='container'>
                <Recognition />
            </div>
            <div className='dataset'>
                <a
                    href='https://data.mendeley.com/datasets/snkd93bnjr/1'
                    target='_blank'
                >Dataset by Hospital Clinic de Barcelona</a>
            </div>
        </div>
    );
}

export default App;
