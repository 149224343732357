import React from "react";

export default function Result({type, score}) {
    score = Math.round(score * 10000) / 100;
    return (
        <div className="result">
            <div className="cell-type">{type}</div>
            <div className="progress-bar-out">
                <div className="progress-bar-text">{String(score)} %</div>
                <div className="progress-bar-in" style={{ width: String(score) +"%" }}></div>
            </div>
        </div>
    )
}