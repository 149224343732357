import React, { useState, useEffect } from 'react';
import FileListItem from './FileListItem';
import FolderListItem from './FolderListItem';

function FileBrowser() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}list`)
            .then((res) => res.json())
            .then((data) => {
                setItems(data.items);
            })
            .catch((err) => console.error(err));
    }, []);


    return (
        <div className='file-browser'>
            <ul>
                {items.map((item) => (
                    item.type === 'directory' ? <FolderListItem
                        key={item.name}
                        item={item}
                        path={'/'}
                    />
                        :
                        <FileListItem
                            key={item.name}
                            item={item}
                            path={'/'}
                        />
                ))}
            </ul>
        </div>
    );
}

export default FileBrowser;
