import React from 'react';

function FileListItem({ item, path }) {
    const { name, size, type } = item;

    const link = `${process.env.REACT_APP_BACKEND_URL}download?path=${path}${name}`;
    return (
        <li>
            <div className='file-list-item'>
                <div>
                    {name}
                </div>
                <div>
                    <a href={link}>
                        Download
                    </a>
                </div>
            </div>
            {size !== '-' && <div className='file-size'>{size}</div>}
            <div className='file-preview'>
                <img src={link} alt={name} />
            </div>
        </li>
    );
}

export default FileListItem;
