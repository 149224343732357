import React from "react";
import Result from "./Result";

export default function Scores({ scores }) {
    return (
        <div className="scores">
            <Result className="progress" score={scores[0]} type={'Basophil:'}></Result>
            <Result className="progress" score={scores[1]} type={'Eosinophil:'}></Result>
            <Result className="progress" score={scores[2]} type={'Erythroblast:'}></Result>
            <Result className="progress" score={scores[3]} type={'Ig:'}></Result>
            <Result className="progress" score={scores[4]} type={'Lymphocyte:'}></Result>
            <Result className="progress" score={scores[5]} type={'Monocyte:'}></Result>
            <Result className="progress" score={scores[6]} type={'Neutrophil:'}></Result>
            <Result className="progress" score={scores[7]} type={'Platelet:'}></Result>
        </div>
    );
}